import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { RobotOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Input, Layout, Button, message, Typography } from 'antd';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import { OpenVidu } from 'openvidu-browser';
import ApiService from '../../utils/api.service';

import JustCastItLogo from './JustCastItLogo.png';
import TouchITLogo from './TouchITLogo.png';

const { Footer } = Layout;
const { Item } = Form;
const { Title } = Typography;

const AppWrapper = styled(Layout)`
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  background: inherit;
`;

const FormWrapper = styled.div`
	position: relative;
	z-index: 1;
	background: #ffffff;
	max-width: 360px;
	margin: 0 auto 100px;
	padding: 45px;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
`;

const CastLogo = styled.img`
	width: 100%;
	max-width: 110px;
	margin: -20px auto;
	display: block;
	padding: 0 0 35px 0;
`;

const FormButton = styled(Button)`
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  /* padding: 15px; */
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  &:hover {
    background: #43a047;
  }
  &:active {
    background: #43a047;
  }
  &:focus {
    background: #43a047;
  }
`;

const AppFooter = styled(Footer)`
  background: inherit;
`;

const ImageFooter = styled.img`
	width: 100%;
	max-width: 250px;
	margin: -20px auto;
	display: block;
	padding: 20px 0;
`;

const formValidation = ({ username = '', sessionId = '' }) => {
	const errors = {};
	if (!username || validator.isEmpty(username)) {
		errors.username = 'This filed is required.';
	}
	if (!sessionId && validator.isEmpty(sessionId)) {
		errors.sessionId = 'This filed is required.';
	}

	return {
		errors,
		isValid: isEmpty(errors)
	};
};

const isFirefox = navigator.userAgent.indexOf('Mozilla');
const isChrome = navigator.userAgent.indexOf('Chrome');
class NsgComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mySessionId: '',
			myUserName: '',
			session: undefined,
			mainStreamManager: undefined,
			publisher: undefined,
			subscribers: [],
			errors: {}
		};

		this.joinSession = this.joinSession.bind(this);
		this.leaveSession = this.leaveSession.bind(this);
		this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
		this.handleChangeUserName = this.handleChangeUserName.bind(this);
		this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
		this.onbeforeunload = this.onbeforeunload.bind(this);
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.onbeforeunload);
		this.setState({ myUserName: '', mySessionId: '' });
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onbeforeunload);
	}

	onbeforeunload(event) {
		this.leaveSession();
	}

	handleChangeSessionId(e) {
		this.setState({
			mySessionId: e.target.value
		});
	}

	handleChangeUserName(e) {
		this.setState({
			myUserName: e.target.value
		});
	}

	handleMainVideoStream(stream) {
		if (this.state.mainStreamManager !== stream) {
			this.setState({
				mainStreamManager: stream
			});
		}
	}

	deleteSubscriber(streamManager) {
		let subscribers = this.state.subscribers;
		let index = subscribers.indexOf(streamManager, 0);
		if (index > -1) {
			subscribers.splice(index, 1);
			this.setState({
				subscribers: subscribers
			});
		}
	}

	async joinSession() {
		// ev.preventDefault();

		try {
			const { isValid, errors } = formValidation({
				username: this.state.myUserName,
				sessionId: this.state.mySessionId
			});
			this.setState({ errors });
			if (!isValid) {
				return true;
			}

			let response = await ApiService.join({
				username: this.state.myUserName,
				sessionId: this.state.mySessionId
			});

			message.success(response.message || 'Welcome', 10);

			// --- 1) Get an OpenVidu object ---

			this.OV = new OpenVidu();

			// --- 2) Init a session ---

			this.setState(
				{
					session: this.OV.initSession()
				},
				async () => {
					var mySession = this.state.session;

					// --- 3) Specify the actions when events take place in the session ---

					// On every new Stream received...
					mySession.on('streamCreated', (event) => {
						// Subscribe to the Stream to receive it. Second parameter is undefined
						// so OpenVidu doesn't create an HTML video by its own
						var subscriber = mySession.subscribe(event.stream, undefined);
						var subscribers = this.state.subscribers;
						subscribers.push(subscriber);

						// Update the state with the new subscribers
						this.setState({
							subscribers: subscribers
						});
					});

					// On every Stream destroyed...
					mySession.on('streamDestroyed', (event) => {
						// Remove the stream from 'subscribers' array
						this.deleteSubscriber(event.stream.streamManager);
					});

					// --- 4) Connect to the session with a valid user token ---

					// 'getToken' method is simulating what your server-side should do.
					// 'token' parameter should be retrieved and returned by your own backend

					try {
						// First param is the token got from OpenVidu Server. Second param can be retrieved by every user on event
						// 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
						mySession
							.connect(response.token, { clientData: this.state.myUserName })
							.then(() => {
								// --- 5) Get your own camera stream ---

								// Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
								// element: we will manage it on our own) and with the desired properties
								let publisher = this.OV.initPublisher(undefined, {
									audioSource: undefined, // The source of audio. If undefined default microphone
									videoSource: 'screen', // The source of video. If undefined default webcam
									publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
									publishVideo: true, // Whether you want to start publishing with your video enabled or not
									resolution: '640x480', // The resolution of your video
									frameRate: 30, // The frame rate of your video
									insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
									mirror: false // Whether to mirror your local video or not
								});

								// --- 6) Publish your stream ---

								mySession.publish(publisher);

								// Set the main video in the page to display our webcam and store our Publisher
								this.setState({
									mainStreamManager: publisher,
									publisher: publisher
								});
							})
							.catch((error) => {
								console.log('There was an error connecting to the session:', error.code, error.message);
							});
					} catch (error) {
						message.error(error.message, 10);
					}
				}
			);
		} catch (error) {
			message.error(error.message, 10);
		}
	}

	leaveSession() {
		// --- 7) Leave the session by calling 'disconnect' method over the Session object ---

		const mySession = this.state.session;

		if (mySession) {
			mySession.disconnect();
		}

		// Empty all properties...
		this.OV = null;
		this.setState({
			session: undefined,
			subscribers: [],
			mySessionId: '',
			myUserName: '',
			mainStreamManager: undefined,
			publisher: undefined
		});
	}

	render() {
		const mySessionId = this.state.mySessionId;
		const myUserName = this.state.myUserName;
		const errors = this.state.errors;
		if (!isChrome && !isFirefox) {
			return (
				<Fragment>
					<AppWrapper>
						<FormWrapper>
							<CastLogo src={JustCastItLogo} alt="Just Cast IT" />
							<Form onFinish={() => null}>
								<Title level={4} style={{ color: '#f0975b' }}>
									Just Cast IT requires the use of Firefox or Chrome Browsers. Please change your
									browser in order to use the App. Please note this is not for Mobiles and Tablets.
									Mobiles and Tablets should use TouchIT Air or Miracast Apps on the LED
								</Title>
							</Form>

							<p className="message">
								<a
									href="https://www.touchittechnologies.com/justcastit"
									target="_blank"
									rel="noopener noreferrer"
								>
									I Need Help
								</a>
							</p>
						</FormWrapper>
					</AppWrapper>

					<AppFooter>
						<ImageFooter src={TouchITLogo} alt="Touch IT Technologies" />
					</AppFooter>
				</Fragment>
			);
		}
		return (
			<Fragment>
				{this.state.session === undefined ? (
					<AppWrapper>
						<FormWrapper>
							<CastLogo src={JustCastItLogo} alt="Just Cast IT" />
							<Form onFinish={this.joinSession}>
								<Item validateStatus={errors.username ? 'error' : ''} help={errors.username}>
									<Input
										prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										size="large"
										name="username"
										id="userName"
										value={myUserName}
										onChange={this.handleChangeUserName}
										placeholder="Enter Your Name"
									/>
								</Item>

								<Item validateStatus={errors.sessionId ? 'error' : ''} help={errors.sessionId}>
									<Input
										prefix={<RobotOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										size="large"
										name="sessionId"
										id="sessionId"
										value={mySessionId}
										onChange={this.handleChangeSessionId}
										placeholder="Enter Casting ID"
									/>
								</Item>

								<FormButton htmlType="submit">Cast My Screen</FormButton>
							</Form>

							<p className="message">
								<a href="https://touchittechnologies.com/" target="_blank" rel="noopener noreferrer">
									I Need Help
								</a>
							</p>
						</FormWrapper>
					</AppWrapper>
				) : null}

				{this.state.session !== undefined ? (
					<AppWrapper>
						<FormWrapper>
							<CastLogo src={JustCastItLogo} alt="Just Cast IT" />
							<Form onFinish={() => null}>
								<Title level={4} style={{ color: '#f0975b' }}>
									Welcome to Just Cast IT. You are now casting your screen to the LED and are able to
									navigate away from this page
								</Title>
							</Form>

							<p className="message">
								<a href="https://touchittechnologies.com/" target="_blank" rel="noopener noreferrer">
									I Need Help
								</a>
							</p>
						</FormWrapper>
					</AppWrapper>
				) : null}

				<AppFooter>
					<ImageFooter src={TouchITLogo} alt="Touch IT Technologies" />
				</AppFooter>
			</Fragment>
		);
	}
}

export default NsgComponent;
